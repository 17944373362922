import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EventResource } from 'src/app/interfaces/event-resource';
import { EventService } from 'src/app/services/event.service';
/**
 * The Data to be passed into the Dialog
 */

export interface DialogData {
  /**
   * The Title of the Dialog
   */
  title: string;
  /**
   * The message in the Dialog
   */
  message: string;
  /**
   * The okText on the Button
   */
  okText: string;
  /**
   * The cancelText on the Button
   */
  cancelText: String;
  /**
   * The Event
   */
  event: EventResource;
}
/**
 * The Dialog to inform the User what the automatic Statechange means
 */
@Component({
  selector: 'app-auto-update-event-status',
  templateUrl: './auto-update-event-status.component.html',
  styleUrls: ['./auto-update-event-status.component.scss']
})
export class AutoUpdateEventStatusComponent implements OnInit {
  /**
   * constructor
   * @param data 
   * @param dialogRef 
   * @param eventService 
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AutoUpdateEventStatusComponent>,
    private eventService: EventService
  ) {

  }
  /**
   * ngOnInit
   */
  ngOnInit(): void {
  }
  /**
   * Close the Dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
  /**
   * Start the bulkupdate for the Events
   */
  update() {
    this.eventService.bulkUpdateEvents(false).subscribe();
  }
}
