import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Contact } from '../interfaces/contact';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClientResource } from '../interfaces/client-resource';
/**
 * The Service to get the Property set by the Admin
 */
@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  /**
   * All properties as BehaviorSubject
   */
  properties$: BehaviorSubject<any> = new BehaviorSubject(null);
  /**
   * The Logo of the Mandant (Shown in the Sidenav)
   */
  logo$: Subject<string> = new ReplaySubject(1);
  /**
   * The Carousell on the Top of the Userview
   */
  component_web_landing_page$: Subject<string> = new ReplaySubject(null);
  /**
   * @deprecated
   */
  component_app_service_center$: Subject<string> = new ReplaySubject(null);
  /**
   * The Boxes at the Bottom of the Userview
   */
  component_web_intro_landing_page$: Subject<string> = new ReplaySubject(null);
  /**
   * The Adventcalendar 
   */
  component_advent$: Subject<string> = new ReplaySubject(null);
/**
 * The ContactData of the Mandant
 */
  contact$: Subject<Contact> = new ReplaySubject(1)
  /**
   * The max of the account a household can contain
   */
  maxAccountsHousehold$: Subject<number> = new ReplaySubject(1)
  /**
   * Du /  Sie 
   */
  formallyAddressing$: BehaviorSubject<boolean> = new BehaviorSubject(false)
/**
 * The Name of the Portal
 */
  portal_label$ = new BehaviorSubject(null)
  /**
   * The Name of the Mandant
   */
  clientName$ = new BehaviorSubject(null)
  /**
   * The Primary Color of the Mandant
   */
  mandantPrimary$: BehaviorSubject<string> = new BehaviorSubject(null)
  /**
   * The Secondary Color of the Mandant
   */
  mandantSecondary$: BehaviorSubject<string> = new BehaviorSubject(null)
  /**
   * The Phonenumber of the Mandant
   */
  phoneContact$: BehaviorSubject<string> = new BehaviorSubject(null)
  /**
   * The Favicon of the Mandant
   */
  favicon$: BehaviorSubject<string> = new BehaviorSubject(null)
/**
 * THe Info of the Mandant
 */
  clientInfo$: BehaviorSubject<any> = new BehaviorSubject(null)
  /**
   * constructor
   * @param http 
   * @param environment 
   */
  constructor(
    private http: HttpClient,
    private environment: EnvironmentService) {
    this.getProperty();

  }
/**
 * Get all Properties
 * @returns 
 */
  getProperties() {
    return this.http.get(`${this.environment.getApiPlain()}/property`);
  }
/**
 * Get all Properties
 * @returns 
 */
  getPropertyConfiguration() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property`))
    )
  }
/**
 * Update a Property
 * @param publicId 
 * @param value 
 * @returns 
 */
  updateProperty(publicId, value) {
    const body = {
      key: publicId,
      value: value
    }
    return this.http.put(`${this.environment.getApiUrl()}/property/${publicId}`, body);
  }

/**
 * Get all Properties and save to the Subjects
 * @returns 
 */
 getProperty() {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property`)
      )
    ).subscribe((properties: any) => {
      let contact: Contact
      properties.map(property => {
        if (property['publicId'] === 'MAIN_COLOR') {
          this.mandantPrimary$.next(property.value)
        } else if (property['publicId'] === 'THEME_COLOR') {
          this.mandantSecondary$.next(property.value)
        } else if (property['publicId'] === 'logo') {
          this.logo$.next(`${this.environment.getApiUrlLogoBaeder()}${property.value}`)
          //  this.logo$.next(`${this.environment.getApiUrlLogo()}${property.value}/file/?imageWidth=200`)
        } else if (property['publicId'] === 'component_web_landing_page') {
          this.component_web_landing_page$.next(JSON.parse(property.value))
        } else if (property['publicId'] === 'component_advent') {
          this.component_advent$.next(JSON.parse(property.value))
        } else if (property['publicId'] === 'component_web_intro_landing_page') {
          this.component_web_intro_landing_page$.next(JSON.parse(property.value))
        } else if (property['publicId'] === 'component_app_service_center') {
          this.component_app_service_center$.next(JSON.parse(property.value))
        } else if (property['publicId'] === 'phone.contact') {
          contact = { ...contact, phone: property.value }
        } else if (property['publicId'] === 'fax.contact') {
          contact = { ...contact, fax: property.value }
        } else if (property['publicId'] === 'info.mail.address') {
          contact = { ...contact, mail: property.value }
        } else if (property['publicId'] === 'max.accounts.household') {
          this.maxAccountsHousehold$.next(property.value)
        } else if (property['publicId'] === 'formally_Addressing') {
          this.formallyAddressing$.next(JSON.parse(property.value))
        } else if (property['publicId'] === 'portal_label') {
          this.portal_label$.next(property.value)
        } else if (property['publicId'] === 'favicon.path') {
          //Slice off mandantID and /
          this.favicon$.next(property.value.slice(4))
        }
      })
      this.properties$.next(properties)
      this.contact$.next(contact);
    })
  }
  /**
   * Get the Name of the Mandant
   * @returns 
   */
  getClientName(): Observable<any> {
    return this.http.get<ClientResource>(`${this.environment.getApiUrl()}`)
  }
/**
 * Get the EventsIDs of the Adeventcalendar
 * @returns 
 */
  getAdventEventIDs(): Observable<any> {
    return this.environment.apiV1$.pipe(
      switchMap(url => this.http.get(`${url}/property/component_advent/`)
      )
    )
  }




}
